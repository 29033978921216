import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./EPass.scss";
import ImgBottom from "../../assets/images/imgbottom.svg";
import ImgTop from "../../assets/images/imgtop.svg";
import ImgVirus from "../../assets/images/virus.svg";
import EPassBackground from "../../assets/icons/e-pass-background.svg";
import htmlToImage from "html-to-image";
import download from "downloadjs";

const content = {
  margin: "0 auto",
  position: "relative",
  height: "480px",
  width: "310px",
  background: "transparent url(" + EPassBackground + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center top",
  marginTop: "10px",
};
const contentEPass = {
  position: "relative",
  textAlign: "center",
  top: "35px",
  margin: "0 auto",
  width: "83%",
  left: "5px",
};
const ePassTitle1 = {
  fontSize: "33px",
  textTransform: "uppercase",
  marginBottom: "20px",
  fontWeight: 600,
};
const ePassTitle2 = {
  fontSize: "28px",
  textTransform: "uppercase",
  marginTop: "15px",
  fontWeight: 600,
};
const ePassTitle3 = {
  fontSize: "23px",
  textTransform: "uppercase",
  marginTop: "10px",
  fontWeight: 600,
};
const ePassTitle4 = {
  fontSize: "16px",
  textTransform: "uppercase",
};
const ePassValidity = {
  margin: "0 auto",
  width: "60%",
  marginTop: "70px",
  fontSize: "16px",
  textAlign: "center",
  fontWeight: 600,
};
const ePassNote = {
  margin: "0 auto",
  textAlign: "justify",
  marginTop: "30px",
  padding: "0 15px",
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: 600,
};

export default class CoverCoughsSneezes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientSelected: false,
      staffSelected: false,
    };
  }

  componentDidMount() {}

  printTicket = () => {
    htmlToImage
      .toPng(document.getElementById("download"))
      .then(function (dataUrl) {
        download(dataUrl, "ticket.png");
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };
  render() {
    return (
      <span>
        <Header {...this.props} />
        <div className="e-pass-main-wrapper">
          <img src={ImgTop} className="imgTop" alt="logo" />
          <div className="main-Section">
            <div className="box">
              <div className="top-bar">
                <div
                  className="cancel"
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                ></div>
                Covid 19 E-Pass
              </div>
              <div className="main-ContentSection">
                <div id="download">
                  <div style={content}>
                    <div style={contentEPass}>
                      <div style={ePassTitle1}>covid-19</div>
                      <div style={ePassTitle4}>This is your covid-19</div>
                      <div style={ePassTitle3}>e-pass</div>
                      <div style={ePassNote}>
                        note :- your temperature may be checked on arrival
                      </div>
                      <div style={ePassValidity}>
                        Your Covid-19 E-pass is valid till
                      </div>
                      <div style={ePassTitle2}>
                        {localStorage.getItem("assesment_date")}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    this.printTicket();
                  }}
                >
                  Download E-Pass{" "}
                  <img src={require("../../assets/icons/ticket.svg")} />
                </button>
              </div>
              <div className="bottom-bar">
                Note:-Please have this available when you attend the healthcare
                facility
              </div>
            </div>
            <img src={ImgBottom} className="imgBottom" alt="logo" />
            <img src={ImgVirus} className="imgVirus" alt="logo" />
          </div>
        </div>
        <Footer />
      </span>
    );
  }
}
