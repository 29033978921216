import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./Dashboard.scss";
import logoImg from "../../assets/images/logo1.svg";
import logoSeth from "../../assets/images/stethoscope.svg";
import logoPass from "../../assets/images/pass.svg";
import ImgBottom from "../../assets/images/imgbottom.svg";
import ImgTop from "../../assets/images/imgtop.svg";
import ImgVirus from "../../assets/images/virus.svg";

import "../../index.scss";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }
  render() {
    return (
      <span>
        <Header {...this.props} />
        <div className="main-wrapper">
          <img src={ImgTop} className="imgTop" alt="logo" />
          <div className="main-Section">
            <div className="box-Dashboard">
              <span>
                <div className="logo-wrapper">
                  <img src={logoImg} className="logo" alt="logo" />
                </div>
                <div className="content">
                  Because of the Covid-19 Global Pandemic, you can help by
                  undertaking some checks before you attend any NHS Tayside
                  healthcare facility.
                  <button
                    className="button-Css"
                    onClick={() => {
                      this.props.history.push("/User");
                    }}
                  >
                    Self assesment
                    <img src={logoSeth} className="logoImg" alt="logo" />
                  </button>
                  {localStorage.getItem("assesment_date") !== null && (
                    <button
                      className={"button-Css active"}
                      onClick={() => {
                        this.props.history.push("/EPass");
                      }}
                    >
                      E-pass{" "}
                      <img src={logoPass} className="logoImg" alt="logo" />
                    </button>
                  )}
                </div>
              </span>
            </div>
          </div>
          <img src={ImgBottom} className="imgBottom" alt="logo" />
          <img src={ImgVirus} className="imgVirus" alt="logo" />
        </div>
        <Footer />
      </span>
    );
  }
}
