import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./UserSelection.scss";
import ImgBottom from "../../assets/images/imgbottom.svg";
import ImgTop from "../../assets/images/imgtop.svg";
import ImgVirus from "../../assets/images/virus.svg";

export default class UserSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientSelected: true,
      staffSelected: false,
    };
  }

  componentDidMount() {}
  render() {
    return (
      <span>
        <Header {...this.props} />
        <div className="main-wrapper">
          <img src={ImgTop} className="imgTop" alt="logo" />
          <div className="main-Section">
            <div className="box-Section">
              <span>
                <div className="back">
                  {(this.state.patientSelected || this.state.staffSelected) && (
                    <img
                      src={require("../../assets/images/left-arrow.svg")}
                      onClick={() => {
                        this.setState({
                          patientSelected: false,
                          staffSelected: false,
                        });
                        // this.props.history.push("/Dashboard");
                      }}
                    />
                  )}
                </div>

                <div className="title">Are you a Patient?</div>
                <div
                  className={
                    this.state.patientSelected
                      ? "img-wrapper patient-logo active"
                      : "img-wrapper patient-logo"
                  }
                >
                  <img
                    src={require("../../assets/images/patient.png")}
                    onClick={() => {
                      this.setState({
                        patientSelected: true,
                        staffSelected: false,
                      });
                    }}
                  />
                  <p>Patient</p>
                </div>
                {/* <div
                  className={
                    this.state.staffSelected
                      ? "img-wrapper staff-logo active"
                      : "img-wrapper staff-logo"
                  }
                >
                  <img
                    src={require("../../assets/images/staff.png")}
                    onClick={() => {
                      this.setState({
                        patientSelected: false,
                        staffSelected: true,
                      });
                    }}
                  />
                  <p>Staff</p>
                </div> */}
                <button
                  className={
                    this.state.patientSelected || this.state.staffSelected
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    if (
                      this.state.patientSelected ||
                      this.state.staffSelected
                    ) {
                      var userType = "Patient";
                      if (this.state.staffSelected) {
                        userType = "Staff";
                      }
                      this.props.history.push("/Question/" + userType);
                    }
                  }}
                >
                  <img src={require("../../assets/images/arrow.svg")} />
                </button>
              </span>
            </div>
          </div>
          <img src={ImgBottom} className="imgBottom" alt="logo" />
          <img src={ImgVirus} className="imgVirus" alt="logo" />
        </div>
        <Footer />
      </span>
    );
  }
}
