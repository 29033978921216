import React, { Component } from "react";
import * as icon from "../../utils/images";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import headerLogo from "../../assets/images/headerlogo.svg";
import "./index.scss";
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mainHeader">
        <div className="header-Logo">
          <img
            src={headerLogo}
            className="logo"
            alt="logo"
            onClick={() => {
              this.props.history.push("/");
            }}
          />
        </div>
      </div>
    );
  }
}
