import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DashboardComponent from "./pages/Dashboard";
import UserSelectionComponent from "./pages/UserSelection";
import QuestionComponent from "./pages/Question";
import CoverCoughsSneezesComponent from "./pages/CoverCoughsSneezes";
import EPassComponent from "./pages/EPass";

import { ToastContainer, toast } from "react-toastify";
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
  }
  routes = [
    {
      path: "/",
      component: DashboardComponent,
      exact: true,
    },
    {
      path: "/Dashboard",
      component: DashboardComponent,
      exact: true,
    },
    {
      path: "/User",
      component: UserSelectionComponent,
      exact: true,
    },
    {
      path: "/Question/:UserType?",
      component: QuestionComponent,
      exact: true,
    },
    {
      path: "/CoverCoughsSneezes",
      component: CoverCoughsSneezesComponent,
      exact: true,
    },
    {
      path: "/EPass",
      component: EPassComponent,
      exact: true,
    },
  ];

  componentDidMount() {}

  render() {
    return (
      <div>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            {this.routes.map((route, index) => {
              return (
                <Route
                  {...this.props}
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
