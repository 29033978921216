import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import "./CoverCoughsSneezes.scss";
import * as icon from "../../utils/images";
import moment from "moment";
import Constant from "../../utils/constants";
import Loader from "../../components/Loader/Loader";
import ToastComponent from "../../components/Toast";
import ImgBottom from "../../assets/images/imgbottom.svg";
import ImgTop from "../../assets/images/imgtop.svg";
import ImgVirus from "../../assets/images/virus.svg";

export default class CoverCoughsSneezes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientSelected: false,
      staffSelected: false,
    };
  }

  componentDidMount() { }
  render() {
    return (
      <span>
        <Header {...this.props} />
        <div className="cover-main-wrapper">
          <img src={ImgTop} className="imgTop" alt="logo" />
          <div className="main-Section">
            <div className="box">
              <div className="top-bar">
                <div
                  className="cancel"
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                ></div>
                Covid 19 risk assessment
              </div>
              <div className="inner-wrapper">
                <div>
                  <div className="img-wrapper">
                    <img src={require("../../assets/images/Q-7-1.svg")} />
                    {/* <div>Avoid touching your face</div> */}
                  </div>
                  <div className="img-wrapper">
                    <img src={require("../../assets/images/Q-7-2.svg")} />
                    {/* <div>Clean your hands often</div> */}
                  </div>
                  <div className="img-wrapper">
                    <img src={require("../../assets/images/Q-7-3.svg")} />
                    {/* <div>Cover coughs and sneezes</div> */}
                  </div>
                </div>
                <div className="text-wrapper">
                  <div>Avoid touching your face</div>
                </div>
                <div className="text-wrapper">
                  <div>Clean your hands often</div>
                </div>
                <div className="text-wrapper last-child">
                  <div>Cover coughs and sneezes</div>
                </div>
                <div className="note">
                  Please telephone the contact number on{" "}
                  your attendance letter, or the contact the department
                  via NHS Tayside Switchboard on <a href="tel:01382660111">01382660111</a>, before
                  attending to discuss your appointment. Up to date NHS
                  advice on the Coronavirus can be found at{" "}
                  <a
                    href="http://www.nhsinform.scot/coronavirus"
                    target="_blank"
                  >www.nhsinform.scot/coronavirus</a>{" "}
                  or by calling <a href="tel:111">111</a>.
                </div>
              </div>
            </div>
          </div>
          <img src={ImgBottom} className="imgBottom" alt="logo" />
          <img src={ImgVirus} className="imgVirus" alt="logo" />
        </div>
        <Footer />
      </span>
    );
  }
}
