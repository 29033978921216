import React, { Component } from "react";
import * as icon from "../../utils/images";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import "./index.scss";
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mainFooter">
        <div className="frame">
          <img
            src={require("../../assets/images/footer_logo1.png")}
            className="logo"
          />
        </div>
      </div>
    );
  }
}
