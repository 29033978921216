import React, { Component, ReactDOM } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./Question.scss";
import moment from "moment";
import Constant from "../../utils/constants";
import Loader from "../../components/Loader/Loader";
import ToastComponent from "../../components/Toast";
import ImgBottom from "../../assets/images/imgbottom.svg";
import ImgTop from "../../assets/images/imgtop.svg";
import ImgVirus from "../../assets/images/virus.svg";

import ScrollToBottom from "react-scroll-to-bottom";

export default class Question extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedUser: this.props.match.params.UserType,
      patientSelected: false,
      staffSelected: false,
      isYes: false,
      lastAnsweredQuestion: 0,
      hideButtons: false,
      isolateText:
        this.props.match.params.UserType === "Patient"
          ? 'Please telephone the booking office on 01382496688 before attending to discuss your appointment. Further advice on the Coronavirus can be found at <a href="http://www.nhsinform.scot/coronavirus">www.nhsinform.scot/coronavirus</a>'
          : "Due to symptoms of COVID or a positive COVID test – It is important you  complete recommended isolation period.  Please ensure your line manager aware if you are self isolating.",
      question1: {
        answer: "",
      },
      question2: {
        answer: "",
      },
      question3: {
        answer: "",
      },
      question4: {
        answer: "",
      },
      question4_1: {
        answer: "",
      },
      question4_2: {
        answer: "",
      },
      question4_3: {
        answer: "",
      },
      question5: {
        answer: "",
      },
      question6: {
        answer: "",
      },
    };
  }

  render() {
    return (
      <span>
        <Header {...this.props} />
        <div className="question-wrapper">
          <img src={ImgTop} className="imgTop" alt="logo" />
          <div className="box">
            <div className="top-bar">Covid 19 risk assessment</div>
            <div className="box-info">
              Because of the Covid-19 Global Pandemic, you can help by
              undertaking some checks before you attend any NHS Tayside
              healthcare facility.
            </div>
            <div className="scroll-wrapper">
              <div className="content-box">
                <ScrollToBottom className="right-column">
                  <div
                    className={
                      this.state.lastAnsweredQuestion >= 2
                        ? "right-content addpostion"
                        : "right-content"
                    }
                  >
                    <span className="question1">
                      <div className="content">
                        Within the past two weeks have you displayed any of the
                        following:
                      </div>
                      {this.state.question1.answer === "" && (
                        <div className="content">
                          <ul>
                            <li>High temperature.</li>
                            <li>
                              New continuous cough or new shortness of breath.
                            </li>
                            <li>New loss of sense of smell.</li>
                          </ul>
                        </div>
                      )}
                    </span>
                    {this.state.selectedUser === "Patient" &&
                      this.state.question1.answer !== "" && (
                        <span>
                          <div className="answer-wrapper">
                            <p className="answer">
                              {this.state.question1.answer}
                            </p>
                          </div>
                          <span className="question2">
                            <div className="content">Thank You</div>
                            {(this.state.question1.answer === "No" ||
                              this.state.lastAnsweredQuestion === 2) && (
                                <div className="content">
                                  In the last 2 weeks have you been in contact
                                  with anyone displaying these symptoms or has
                                  been confirmed positive for Covid 19?
                                </div>
                              )}
                          </span>
                        </span>
                      )}
                    {this.state.selectedUser === "Staff" &&
                      this.state.question1.answer !== "" && (
                        <span>
                          <div className="answer-wrapper">
                            <p className="answer">
                              {this.state.question1.answer}
                            </p>
                          </div>
                          <span className="question2">
                            <div className="content">Thank You</div>
                            {(this.state.question1.answer === "No" ||
                              this.state.lastAnsweredQuestion === 2) && (
                                <div className="content">
                                  Outside of work, have you been in recent contact
                                  with anyone displaying these symptoms or has
                                  been confirmed positive for Covid 19?
                                </div>
                              )}
                          </span>
                        </span>
                      )}

                    {this.state.selectedUser === "Patient" &&
                      this.state.question2.answer !== "" && (
                        <span>
                          <div className="answer-wrapper">
                            <p className="answer">
                              {this.state.question2.answer}
                            </p>
                          </div>
                          <span className="question3">
                            <div className="content">Thank You</div>
                            {(this.state.question2.answer === "No" ||
                              this.state.lastAnsweredQuestion === 3) && (
                                <div className="content">
                                  Have you travelled abroad in the last 2 weeks to a country which the current government advice requires you to quarantine on your return to the UK?
                                </div>
                              )}
                          </span>
                        </span>
                      )}
                    {this.state.selectedUser === "Staff" &&
                      this.state.question2.answer !== "" && (
                        <span>
                          <div className="answer-wrapper">
                            <p className="answer">
                              {this.state.question2.answer}
                            </p>
                          </div>
                          <span className="question3">
                            <div className="content">Thank You</div>
                            {(this.state.question2.answer === "No" ||
                              this.state.lastAnsweredQuestion === 3) && (
                                <div className="content">
                                  Have you been advised in writing to be
                                  "Shielding" for a period of 12 weeks?
                                </div>
                              )}
                          </span>
                        </span>
                      )}
                    {this.state.question3.answer !== "" && (
                      <span>
                        <div className="answer-wrapper">
                          <p className="answer">
                            {this.state.question3.answer}
                          </p>
                        </div>
                        <span className="question4">
                          <div className="content">Thank You</div>
                          {/* {this.state.selectedUser === "Patient" &&
                            (this.state.question3.answer === "No" ||
                              this.state.lastAnsweredQuestion === 4) && (
                              <div className="content">
                                Have you been advised in writing to be
                                "Shielding" for a period of 12 weeks?
                              </div>
                            )}
                          {this.state.selectedUser === "Staff" &&
                            this.state.question3.answer === "No" && (
                              <div className="content">
                                Please contact the number on your attendance
                                letter and explain you are shielding. They will
                                discuss this with the relevant clinician and get
                                back to you
                              </div>
                            )} */}
                        </span>
                      </span>
                    )}
                    {this.state.selectedUser === "Patient" &&
                      this.state.question4.answer !== "" &&
                      this.state.question4.answer === "No" && (
                        <span>
                          <div className="answer-wrapper">
                            <p className="answer">
                              {this.state.question4.answer}
                            </p>
                          </div>
                          <span className="question4">
                            <div className="content">Thank You</div>
                            {/* <div className="content">
                              Please contact the number on your attendance
                              letter and explain you are shielding. They will
                              discuss this with the relevant clinician and get
                              back to you
                            </div> */}
                          </span>
                        </span>
                      )}
                    {this.state.selectedUser === "Patient" &&
                      this.state.question4.answer === "Yes" && (
                        <span>
                          <div className="answer-wrapper">
                            <p className="answer">
                              {this.state.question4.answer}
                            </p>
                          </div>
                          <span className="question4">
                            <div className="content">Thank You</div>
                            <div className="content">
                              Does the hospital know that you are shielding?
                            </div>
                          </span>
                        </span>
                      )}
                    {this.state.question4_1.answer === "Yes" && (
                      <div className="content">Thank You</div>
                    )}
                    {this.state.lastAnsweredQuestion === 4.1 && (
                      <span>
                        <div className="answer-wrapper">
                          <p className="answer">
                            {this.state.question4.answer}
                          </p>
                        </div>
                        <div className="content">Thank You</div>
                      </span>
                    )}
                    {this.state.isYes && (
                      <div className="content">
                        Please telephone the contact number on
                        your attendance letter, or the contact the department
                        via NHS Tayside Switchboard on <a href="tel:01382660111">01382660111</a>, before
                        attending to discuss your appointment. Up to date NHS
                        advice on the Coronavirus can be found at
                        <a
                          href="http://www.nhsinform.scot/coronavirus"
                          target="_blank"
                        >
                          www.nhsinform.scot/coronavirus
                        </a> or by calling <a href="tel:111">111</a>.
                      </div>
                    )}
                    <section className="bottom"></section>
                  </div>
                </ScrollToBottom>
              </div>
              <div className="left-column">
                <img src={require("../../assets/images/Doctor.svg")} />
              </div>
            </div>

            <div className="bottom-wrapper">
              {!this.state.hideButtons && (
                <span>
                  <button
                    className="bottom-btn"
                    onClick={(e) => {
                      if (this.state.question1.answer === "") {
                        this.setState({
                          question1: { answer: "Yes" },
                          lastAnsweredQuestion: 1,
                        });
                        this.setState({ hideButtons: true });
                        this.setState({ isYes: true });
                        localStorage.clear();
                        setTimeout(() => {
                          this.props.history.push("/CoverCoughsSneezes");
                        }, 5000);
                      } else if (this.state.question2.answer === "") {
                        this.setState({
                          question2: { answer: "Yes" },
                          lastAnsweredQuestion: 2,
                        });
                        this.setState({ isYes: true });
                        this.setState({ hideButtons: true });
                        localStorage.clear();
                        setTimeout(() => {
                          this.props.history.push("/CoverCoughsSneezes");
                        }, 5000);
                      } else if (this.state.question3.answer === "") {
                        this.setState({
                          question3: { answer: "Yes" },
                          lastAnsweredQuestion: 3,
                        });
                        this.setState({ isYes: true });
                        this.setState({ hideButtons: true });
                        localStorage.clear();
                        setTimeout(() => {
                          this.props.history.push("/CoverCoughsSneezes");
                        }, 5000);
                      }
                      // else if (this.state.question4.answer === "") {
                      //   this.setState({ isYes: false });
                      //   this.setState({
                      //     question4: { answer: "Yes" },
                      //     lastAnsweredQuestion: 4,
                      //   });
                      //   this.setState({ hideButtons: false });
                      // } else if (this.state.question4_1.answer === "") {
                      //   this.setState(
                      //     {
                      //       question4_1: { answer: "Yes" },
                      //       lastAnsweredQuestion: 4,
                      //       hideButtons: true,
                      //     },
                      //     () => {
                      //       localStorage.setItem(
                      //         "assesment_date",
                      //         moment().add(4, "d").format("DD-MMMM-YYYY")
                      //       );
                      //       let url = "EPass";
                      //       setTimeout(() => {
                      //         this.props.history.push("/" + url);
                      //       }, 5000);
                      //     }
                      //   );
                      // }
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="bottom-btn"
                    onClick={(e) => {
                      if (this.state.question1.answer === "") {
                        this.setState({
                          question1: { answer: "No" },
                          lastAnsweredQuestion: 1,
                        });
                      } else if (this.state.question2.answer === "") {
                        this.setState({
                          question2: { answer: "No" },
                          lastAnsweredQuestion: 2,
                        });
                      } else if (this.state.question3.answer === "") {
                        this.setState({
                          question3: { answer: "No" },
                          lastAnsweredQuestion: 3,
                          hideButtons: true,
                        });
                        let url = "EPass";
                        if (this.state.isYes) {
                          url = "CoverCoughsSneezes";
                        }
                        setTimeout(() => {
                          this.props.history.push("/" + url);
                        }, 5000);
                        // if (this.state.selectedUser === "Staff") {
                        //   this.setState(
                        //     {
                        //       hideButtons: true,
                        //     },
                        //     () => {
                        //       localStorage.setItem(
                        //         "assesment_date",
                        //         moment().add(4, "d").format("DD-MMMM-YYYY")
                        //       );
                        //       let url = "EPass";
                        //       if (this.state.isYes) {
                        //         url = "CoverCoughsSneezes";
                        //       }
                        //       setTimeout(() => {
                        //         this.props.history.push("/" + url);
                        //       }, 5000);
                        //     }
                        //   );
                        // }
                      }
                      // else if (this.state.question4.answer === "") {
                      //   this.setState(
                      //     {
                      //       question4: { answer: "No" },
                      //       lastAnsweredQuestion: 4,
                      //       hideButtons: true,
                      //     },
                      //     () => {
                      //       localStorage.setItem(
                      //         "assesment_date",
                      //         moment().add(4, "d").format("DD-MMMM-YYYY")
                      //       );
                      //       let url = "EPass";
                      //       if (this.state.isYes) {
                      //         url = "CoverCoughsSneezes";
                      //       }
                      //       setTimeout(() => {
                      //         this.props.history.push("/" + url);
                      //       }, 5000);
                      //     }
                      //   );
                      // } else if (this.state.question4_1.answer === "") {
                      //   this.setState(
                      //     {
                      //       question4_1: { answer: "No" },
                      //       lastAnsweredQuestion: 4.1,
                      //       hideButtons: true,
                      //       isYes: true,
                      //     },
                      //     () => {
                      //       localStorage.clear();
                      //       setTimeout(() => {
                      //         this.props.history.push("/CoverCoughsSneezes");
                      //       }, 5000);
                      //     }
                      //   );
                      // }
                    }}
                  >
                    No
                  </button>
                </span>
              )}

              {this.state.selectedUser === "Patient" && (
                <img
                  src={require("../../assets/images/patient-selected.svg")}
                />
              )}
              {this.state.selectedUser === "Staff" && (
                <img src={require("../../assets/images/staff-selected.svg")} />
              )}
            </div>
          </div>
          <img src={ImgBottom} className="imgBottom" alt="logo" />
          <img src={ImgVirus} className="imgVirus" alt="logo" />
        </div>
        <Footer />
      </span>
    );
  }
}
